import React from 'react';
import './FAQPage.css'; // Make sure to have this CSS file in your project for styling
import { Helmet } from 'react-helmet'; // Import Helmet

const FAQPage = () => {
  return (
    <div className="faq-page">
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={`${process.env.PUBLIC_URL}/images/favicon.ico`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Frequently asked questions about The Give Back Grid, a unique platform combining digital creativity with charitable giving." />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/images/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.PUBLIC_URL}/images/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.PUBLIC_URL}/images/favicon-16x16.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/images/site.webmanifest`} />
        <title>FAQ - The Give Back Grid</title>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.givebackgrid.com/faq" />
        <meta property="og:title" content="FAQ - The Give Back Grid" />
        <meta property="og:description" content="Learn more about how The Give Back Grid works, how to participate, and how it supports charitable causes." />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.givebackgrid.com/faq" />
        <meta name="twitter:title" content="FAQ - The Give Back Grid" />
        <meta name="twitter:description" content="Learn more about how The Give Back Grid works, how to participate, and how it supports charitable causes." />
        <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />
      
        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-J27ZFN16BR"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-J27ZFN16BR');
          `}
        </script>
      </Helmet>
      
      <main className="faq-content">
        <h1>Frequently Asked Questions</h1>
        <div className="faq-section">
          {[
            {
              question: "What is The Give Back Grid?",
              answer: "The Give Back Grid is a unique digital platform that combines the creativity of a digital grid with charitable giving. Participants can purchase boxes on a canvas to display images and links, contributing to a collective artwork while supporting various causes."
            },
            {
              question: "How can I participate in the project?",
              answer: "Participation is simple. Explore the grid to find an empty box that resonates with you, purchase it, and then personalize it with your image, a URL, and descriptive text. Your piece will then become part of the larger grid."
            },
            {
              question: "What happens after I buy a box?",
              answer: "After purchasing a box, you'll have the opportunity to personalize it by uploading an image, adding a URL, and providing descriptive text. Your contribution will then be processed and integrated into the grid within 24-48 hours."
            },
            {
              question: "Can I buy more than one box?",
              answer: "Absolutely! You can buy multiple boxes to create a larger space for your image and message. This allows for greater visibility and a larger canvas for your personal or brand expression."
            },
            {
              question: "Are there different pricing tiers?",
              answer: "Yes, the project features a tiered pricing system to maintain exclusivity. Each tier offers a limited number of boxes at different price points, starting at $20. As tiers fill up, the price for subsequent tiers increases."
            },
            {
              question: "How is the pricing structured?",
              answer: "Pricing is structured in tiers, with each tier having a set number of boxes available at a specific price. Prices start at $20 for Tier 1 and increase with each tier, reflecting the growing exclusivity and demand."
            },
            {
              question: "Can anyone see the images and links I upload?",
              answer: "Yes, once your box is live on the grid, anyone visiting the site can view your image and click on your link. It's a great way to share your message, brand, or cause with a wider audience."
            },
            {
              question: "How does The Give Back Grid support charitable causes?",
              answer: "While The Give Back Grid is a for-profit entity, a designated portion of the proceeds is allocated to support charitable initiatives. By participating, you're not only contributing to a piece of digital art but also supporting broader philanthropic efforts."
            },
            {
              question: "Is there a guideline for the content I can upload?",
              answer: "Yes, all content must be appropriate for a general audience, adhering to a 13+ age guideline. This ensures that the grid remains a positive and inclusive space for all participants and viewers."
            },
            {
              question: "What if I miss out on the current tier pricing?",
              answer: "As tiers fill up, the opportunity to participate at a lower price point diminishes. If you're interested in securing a spot at the current pricing, it's best to act quickly before moving to the next tier with a higher price."
            }
          ].map((faq, index) => (
            <div key={index} className="faq-item">
              <h3><strong>Q:</strong> {faq.question}</h3>
              <p><strong>A:</strong> {faq.answer}</p>
            </div>
          ))}
        </div>
        <div className="faq-more-questions">
          <p>Have more questions? Email us at <a href="mailto:info@givebackgrid.com">info@givebackgrid.com</a></p>
        </div>
      </main>
    </div>
  );
};

export default FAQPage;