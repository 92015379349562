import React from 'react';
import './footer.css';


const Footer = () => {
  return (
    <footer>
    <p>&copy; The Give Back Grid 2024</p>
    <div>
    {/* <a href="tel:+18010222234">📞 801-022-2234</a> */}
    <a href="mailto:info@givebackgrid.com">📧 info@givebackgrid.com</a>
  </div>
  </footer>  
  );
};

export default Footer;
