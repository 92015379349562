import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import './grid.css';

function Grid() {
  const [boxData, setBoxData] = useState(Array(2500).fill(null));
  const [tooltip, setTooltip] = useState({ show: false, content: '', position: { x: 0, y: 0 } });

  useEffect(() => {
    const fetchAllBoxData = async () => {
      const querySnapshot = await getDocs(collection(db, "boxes"));
      const boxes = Array(2500).fill(null);

      querySnapshot.forEach((doc) => {
        const boxIndex = parseInt(doc.id, 10) - 1;
        if (boxIndex >= 0 && boxIndex < 2500) {
          boxes[boxIndex] = doc.data();
        }
      });

      setBoxData(boxes);
    };

    fetchAllBoxData();
  }, []);

  const onMouseEnterBox = (index, data, e) => {
    // Check if window width is above a typical mobile breakpoint (e.g., 768px)
    if (data && data.altText && window.innerWidth > 768) {
      setTooltip({
        show: true,
        content: `${data.altText}`,
        position: { x: e.clientX + 10, y: e.clientY + 15 }
      });
    }
  };

  const onMouseLeaveBox = () => {
    // Reset tooltip when leaving the box only if on desktop
    if (window.innerWidth > 768) {
      setTooltip({ show: false, content: '', position: { x: 0, y: 0 } });
    }
  };

  const handleClick = (data) => {
    if (data && data.url) {
      window.open(data.url, '_blank');
    } else {
      window.open('https://buy.stripe.com/aEUeVz3OtemydWMdQW', '_blank');
    }
  };

  return (
    <div className="grid-container">
      <img src='/images/grid.webp' alt="Grid" className="grid-image" />
      <div className="overlay">
        {boxData.map((data, index) => (
          <div
            key={index}
            className="grid-box"
            onMouseEnter={(e) => onMouseEnterBox(index, data, e)}
            onMouseLeave={onMouseLeaveBox}
            onClick={() => handleClick(data)}
          />
        ))}
      </div>
      {tooltip.show && (
        <div className="tooltip" style={{ top: `${tooltip.position.y}px`, left: `${tooltip.position.x}px` }}>
          <p dangerouslySetInnerHTML={{ __html: tooltip.content.replace(/\n/g, '<br/>') }}></p>
        </div>
      )}
    </div>
  );
}

export default Grid;
