import React from 'react';
import { NavLink } from 'react-router-dom';
import './header.css';

function Header() {
  return (
    <header>
      <div className="logoContainer">
        <NavLink to="/" className="logoLink">
          <img src="/images/gbgclear.png" alt="Logo" className="logo" />
        </NavLink>
      </div>

      <div className="header-middle">
      <p>Be Seen | <span className="give">Give Back</span> | Limited Spacing </p>
      {/* <span className="highlight">Prices Rising</span */}
    </div>


      <a href="https://buy.stripe.com/aEUeVz3OtemydWMdQW" className="header-right-link" target="_blank" rel="noopener noreferrer">
        <div className="header-right">
          <p className="header-action">📦 Buy a Square 📦</p>
          <p className="header-action">🖼️ Upload an Image 🖼️</p>
          <p className="header-action">🖥️ Link Your Website 🖥️</p>
          <p className="header-action-buy">Click here to buy!</p>
        </div>
      </a>

    </header>
  );
}

export default Header;