import React from 'react';
import './HowItWorks.css'; 
import { Helmet } from 'react-helmet';

const HowItWorks = () => {
  return (
    <div className="how-it-works-page">
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={`${process.env.PUBLIC_URL}/images/favicon.ico`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Discover how The Give Back Grid empowers creativity for charity. Learn about our tiered system and how you can contribute." />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/images/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.PUBLIC_URL}/images/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.PUBLIC_URL}/images/favicon-16x16.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/images/site.webmanifest`} />
        <title>How It Works - The Give Back Grid</title>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.givebackgrid.com/how-it-works" />
        <meta property="og:title" content="How It Works - The Give Back Grid" />
        <meta property="og:description" content="Discover how The Give Back Grid empowers creativity for charity. Learn about our tiered system and how you can contribute." />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.givebackgrid.com/how-it-works" />
        <meta name="twitter:title" content="How It Works - The Give Back Grid" />
        <meta name="twitter:description" content="Discover how The Give Back Grid empowers creativity for charity. Learn about our tiered system and how you can contribute." />
        <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />
      
        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-J27ZFN16BR"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-J27ZFN16BR');
          `}
        </script>
      </Helmet>

      <main className="how-it-works-content">
        <h1>How The Give Back Grid Works</h1>
        
        <section className="intro-section">
          <h2>Welcome to Our Community</h2>
          <div className="intro-content-wrapper">
              <img src="../images/mosaic.webp" alt="Introduction" className="intro-image"/>
              <div className="intro-content">
                  <p>Welcome to The Give Back Grid, a creative platform supporting charitable causes. Here, you can leave a personal mark by selecting a canvas box, making a purchase, and sharing your image and story. Together, we create a vibrant mosaic, amplifying our collective impact.</p>
              </div>
          </div>
      </section>


        <section className="tiered-system-section">
          <h2>Participation Tiers</h2>
          <p>Our tiered system ensures variety and engagement. Each tier offers a limited number of boxes, adding to the uniqueness of your contribution. As tiers fill, the value of participation increases, so act quickly to secure your spot and be part of our evolving story.</p>
          <ul className="tier-list">
            <li>Tier 1: 300 boxes at $20 each - Be an early participant.</li>
            <li>Tier 2: 525 boxes at $200 each - Enhance your contribution.</li>
            <li>Tier 3: 625 boxes at $600 each - Join the growing community.</li>
            <li>Tier 4: 550 boxes at $1,200 each - Don't miss out.</li>
            <li>Tier 5: 500 boxes at $2,000 each - Secure the final spots.</li>
          </ul>
        </section>

        <section className="customization-section">
          <h2>Customize Your Contribution</h2>
          <p>Make your mark on our grid by personalizing your box. Start by selecting your canvas, then customize it at checkout by uploading an image, a link, and a message. After approval, your contribution will be added to the grid showcasing your story alongside others in our community.</p>
          <ol>
            <li>Choose your spot and size on the grid.</li>
            <li>Customize your box with an image, link, and message at checkout.</li>
            <li>Wait for us to integrate your contribution into the grid.</li>
            <li>See your personalized box go live, sharing your story with the world.</li>
          </ol>
        </section>

        <section className="support-section">
          <h2>Make a Difference</h2>
          <div className="support-content-wrapper">
            <div className="support-content">
              <p>The Give Back Grid is more than a digital art project; it's a commitment to making a difference. Every box you purchase contributes to our collective story and supports charitable causes. We're a for-profit organization with a heart for philanthropy, dedicating a portion of our proceeds to impactful charities. Join us in creating a tapestry of change, where every box contributes to a better world.</p>
            </div>
            <img src="/images/community2.webp" alt="Support" className="support-image"/>
          </div>
        </section>
      </main>
    </div>
  );
}

export default HowItWorks;
