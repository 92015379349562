import React from 'react';
import './HowToPopup.css'; // Make sure this path is correct for your CSS file

function HowToPopup({ onClose }) {
  return (
    <div className="how-to-popup-overlay">
      <div className="how-to-popup">
        <h1>Welcome to The Give Back Grid!</h1>
        <p>Follow these steps to make your mark on the Grid:</p>
        <ol>
          <li><strong>Select a Box:</strong> Choose an available box to feature your brand. Buy multiple boxes to create a bigger and more impactful display.</li>
          <li><strong>Link to Your Site:</strong> Your image will link directly to your website, amplifying your brand's reach.</li>
          <li><strong>Leverage Tiered Pricing:</strong> Act swiftly! Early birds benefit from lower prices. As availability diminishes, prices rise.</li>
        </ol>
        <img src="/images/howto.webp" alt="Expand Your Canvas" className="how-to-image" />
        <button onClick={onClose}>Got It!</button>
      </div>
    </div>
  );
}

export default HowToPopup;
