import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
import './navbar.css'; // Assuming a separate CSS file for NavBar

function NavBar() {
  return (
    <nav className="sub-nav">
      <ul>
        <li><NavLink to="/" className={({ isActive }) => isActive ? 'subNavLinkActive' : 'subNavLink'}>Home</NavLink></li>
        <li><NavLink to="/how-it-works" className={({ isActive }) => isActive ? 'subNavLinkActive' : 'subNavLink'}>How To</NavLink></li>
        <li><NavLink to="/cause" className={({ isActive }) => isActive ? 'subNavLinkActive' : 'subNavLink'}>Cause</NavLink></li>
        <li><NavLink to="/faq" className={({ isActive }) => isActive ? 'subNavLinkActive' : 'subNavLink'}>FAQ</NavLink></li>
        <li><NavLink to="/blog" className={({ isActive }) => isActive ? 'subNavLinkActive' : 'subNavLink'}>Blog</NavLink></li>
      </ul>
    </nav>
  );
}

export default NavBar;
