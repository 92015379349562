import React from 'react';
import './OurCause.css';
import { Helmet } from 'react-helmet';

function OurCause() {
  return (
    <div className="our-cause-page">
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={`${process.env.PUBLIC_URL}/images/favicon.ico`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Learn about our cause and how we dedicate our efforts to support various charitable initiatives through strategic partnerships." />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/images/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.PUBLIC_URL}/images/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.PUBLIC_URL}/images/favicon-16x16.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/images/site.webmanifest`} />
        <title>Our Cause - The Give Back Grid</title>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.givebackgrid.com/our-cause" />
        <meta property="og:title" content="Our Cause - The Give Back Grid" />
        <meta property="og:description" content="Discover how we're making a difference through strategic partnerships with leading charitable organizations." />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.givebackgrid.com/our-cause" />
        <meta name="twitter:title" content="Our Cause - The Give Back Grid" />
        <meta name="twitter:description" content="Discover how we're making a difference through strategic partnerships with leading charitable organizations." />
        <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />

        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-J27ZFN16BR"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-J27ZFN16BR');
          `}
        </script>
      </Helmet>

      <h1>Our Cause</h1>
      <section className="cause-section">
        <h2>Partnering with Leading Organizations for Greater Impact</h2>
        <p>At Give Back Grid, we are committed to making a tangible difference through strategic partnerships with some of the world's most respected charitable organizations. Our collaborations amplify our efforts, ensuring that every contribution we receive goes further in creating positive change.</p>
        <p>We proudly partner with:</p>
        <ul>
          <li><strong>World Wildlife Fund (WWF):</strong> Supporting wildlife conservation and habitat preservation.</li>
          <li><strong>Habitat for Humanity:</strong> Helping to build homes for families in need.</li>
          <li><strong>Charity: Water:</strong> Providing clean and safe drinking water to communities around the world.</li>
          <li><strong>Feeding America:</strong> Fighting hunger through food banks and hunger relief programs.</li>
        </ul>
      </section>

      <section className="cause-section">
        <h2>Upcoming Projects</h2>
        <p>We are excited to announce several upcoming projects aimed at expanding our reach and deepening our impact:</p>
        <ul>
          <li><strong>Environmental Conservation Initiatives:</strong> Working with WWF to protect endangered species and preserve natural habitats.</li>
          <li><strong>Affordable Housing Programs:</strong> Collaborating with Habitat for Humanity to build sustainable homes in underserved communities.</li>
          <li><strong>Clean Water Campaigns:</strong> Partnering with Charity: Water to install water purification systems in remote villages.</li>
          <li><strong>Hunger Relief Efforts:</strong> Teaming up with Feeding America to provide nutritious meals to families facing food insecurity.</li>
        </ul>
      </section>

      <section className="cause-section">
        <h2>Join Us in Making a Difference</h2>
        <p>We invite you to join us in our mission to create a better world. Your support helps us fund these vital initiatives and bring hope to those who need it most. Together, we can achieve remarkable things and leave a lasting legacy of kindness and generosity.</p>
        <p>Stay tuned for more updates and be part of our journey to make a significant impact.</p>
      </section>

      <section className="cause-section">
        <h2>Get Involved</h2>
        <p>Whether you are an individual looking to make a difference, a company seeking to fulfill its corporate social responsibility, or a community group wanting to support a cause, Give Back Grid offers numerous opportunities for you to get involved. Contact us at <a href="mailto:partnerships@givebackgrid.com">partnerships@givebackgrid.com</a> to learn more about how you can partner with us.</p>
      </section>
    </div>
  );
}

export default OurCause;
