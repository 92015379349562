import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header'; // Ensure the Header component is correctly imported
import NavBar from './components/navbar'; // Ensure the NavBar component is correctly imported
import Footer from './components/footer'; // Ensure the Footer component is correctly imported
import HomePage from './pages/homepage';
import Blog from './pages/blogs/blog';
import HowItWorks from './pages/HowItWorks';
import FAQPage from './pages/FAQPage';
import OurCause from './pages/OurCause';
import BlogPost1 from './pages/blogs/blogpost1'; // Adjust the import path
import BlogPost2 from './pages/blogs/blogpost2'; // Adjust the import path
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/cause" element={<OurCause />} />
          <Route path="/blogs/blogpost1" element={<BlogPost1 />} />
          <Route path="/blogs/blogpost2" element={<BlogPost2 />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
