import React from 'react';
import { Link } from 'react-router-dom';
import './blogpost.css';
import { Helmet } from 'react-helmet';

const BlogPost2 = () => {
  return (
    <div className="blog-post-page">
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={`${process.env.PUBLIC_URL}/images/favicon.ico`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Join our affiliate program and help raise awareness for a good cause while earning compensation from our sponsors. Contact affiliate@givebackgrid.com for more info." />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/images/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.PUBLIC_URL}/images/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.PUBLIC_URL}/images/favicon-16x16.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/images/site.webmanifest`} />
        <title>Affiliate Program - The Give Back Grid</title>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.givebackgrid.com/blog" />
        <meta property="og:title" content="Join Our Affiliate Program" />
        <meta property="og:description" content="Join our affiliate program and help raise awareness for a good cause while earning compensation from our sponsors. Contact affiliate@givebackgrid.com for more info." />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.givebackgrid.com/blog" />
        <meta name="twitter:title" content="Join Our Affiliate Program" />
        <meta name="twitter:description" content="Join our affiliate program and help raise awareness for a good cause while earning compensation from our sponsors. Contact affiliate@givebackgrid.com for more info." />
        <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />
      
        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-J27ZFN16BR"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-J27ZFN16BR');
          `}
        </script>
      </Helmet>
      <div className="blog-post-header">
        <h1>Join Our Affiliate Program: Be Part of the Movement</h1>
        <p className="blog-post-date">Posted on June 13th, 2024 by A.B.</p>
      </div>

      <div className="blog-post-content">
        <section className="introduction-section">
          <h2>Become an Affiliate and Support a Great Cause</h2>
          <p>Are you passionate about making a difference? Join our affiliate program and become a part of a community dedicated to positive change. By becoming an affiliate, you'll have the opportunity to earn compensation from our sponsors while helping to raise awareness for The Give Back Grid. As an added benefit, affiliates will receive a FREE spot on our homepage to showcase their support and contributions.</p>
        </section>

        <section className="founders-journey-section">
          <h2>Why Join Our Affiliate Program?</h2>
          <p>Our affiliate program offers a unique opportunity to contribute to a good cause while earning rewards. Each person you refer to our site helps raise funds, and you will earn money from our sponsors for your efforts. Additionally, as an affiliate, you'll have a say in where donations are directed, allowing you to support causes that matter most to you. Whether you're looking for a side hustle or a way to give back to the community, our affiliate program is the perfect fit. Top affiliates will also be invited to participate in exclusive charitable activities, including flights and other special events.</p>
          <p>Whether you're looking for a side hustle or a way to give back to the community, our affiliate program is the perfect fit. You'll be supported by our team and have access to resources that will help you succeed.</p>
        </section>

        <section className="founders-journey-section">
          <h2>How to Get Started</h2>
          <p>Getting started is easy. Simply contact us at <a href="mailto:affiliate@givebackgrid.com">affiliate@givebackgrid.com</a> to learn more about the program and how you can get involved. This opportunity is perfect for individuals with a TikTok, Facebook, Instagram, or any other social media following who believe their audience would be interested in donating. Share your custom link, and we'll track all referrals to ensure you receive credit for every donation made through your network. We're excited to have you join us on this journey and look forward to working together to make a difference.</p>
        </section>

        <section className="founders-journey-section">
          <h2>Sharing Our Daily Blog Content</h2>
          <p>In addition to promoting our platform, you can also share our custom daily blog content from <a href="https://givebackgrid.net" target="_blank" rel="noopener noreferrer">givebackgrid.net</a>. If you find any articles useful or inspiring, feel free to share them with your followers. Don't have a large following but still want to help? You can make an impact by sharing our articles on <a href="https://givebackgrid.net" target="_blank" rel="noopener noreferrer">givebackgrid.net</a> with your friends, family, and community. Every share helps us reach more people and spread awareness about the causes we support.</p>
        </section>  

        <section className="founders-journey-section">
          <h2>Making an Impact Together</h2>
          <p>As an affiliate, you'll be part of a larger movement that's dedicated to supporting various charitable causes. Your efforts will help us continue to fund important projects and initiatives that make a real difference in people's lives.</p>
          <p>Join us today and be a part of something bigger. Together, we can achieve great things and create a lasting impact.</p>
        </section>
      </div>

      <div className="blog-post-footer">
        <Link to="/blog" className="back-to-blog">← Back to Blog</Link>
      </div>
    </div>
  );
};

export default BlogPost2;
