import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Make sure to import Helmet
import './blog.css';

const Blog = () => {
  return (
    <div className="blog-page">
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={`${process.env.PUBLIC_URL}/images/favicon.ico`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Explore our blog to learn about The Give Back Grid's latest updates, community stories, and how we're making a difference together." />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/images/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.PUBLIC_URL}/images/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.PUBLIC_URL}/images/favicon-16x16.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/images/site.webmanifest`} />
        <title>Blog - The Give Back Grid</title>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.givebackgrid.com/blog" />
        <meta property="og:title" content="The Give Back Grid Blog" />
        <meta property="og:description" content="Explore our blog to learn about The Give Back Grid's latest updates, community stories, and how we're making a difference together." />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.givebackgrid.com/blog" />
        <meta name="twitter:title" content="The Give Back Grid Blog" />
        <meta name="twitter:description" content="Explore our blog to learn about The Give Back Grid's latest updates, community stories, and how we're making a difference together." />
        <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />
      
        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-J27ZFN16BR"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-J27ZFN16BR');
          `}
        </script>
      </Helmet>

      <div className="blog-content">
      <Link to="/blogs/blogpost2" className="blog-post-link"> {/* Updated Link */}
          <article className="blog-post">
            <img src="/images/gbgclear.png" alt="Sample Post" className="post-image" />
            <div className="post-content">
              <h2 className="post-title">Become an Affiliate!</h2>
              <p className="post-metadata">Posted on Jun 13th, 2024 by A.B.</p>
              <p className="post-body">Join our affiliate program, personalize your space with an image or message, and earn while promoting a great cause...</p>
            </div>
          </article>
        </Link>
        <Link to="https://www.givebackgrid.net" className="blog-post-link" target="_blank" rel="noopener noreferrer"> 
          <article className="blog-post">
            <img src="/images/gbgclear.png" alt="Dedicated Blog" className="post-image" />
            <div className="post-content">
              <h2 className="post-title">Dedicated Newsroom</h2>
              <p className="post-metadata">Posted on June 1st, 2024 by A.B.</p>
              <p className="post-body">This dedicated newsroom will feature daily articles focused on charitable initiatives, community stories, and ways to make a positive impact. Visit our newsroom to stay informed and be part of our growing community.</p>
            </div>
          </article>
        </Link>
        <Link to="/blogs/blogpost1" className="blog-post-link"> {/* Updated Link */}
          <article className="blog-post">
            <img src="/images/gbgclear.png" alt="Sample Post" className="post-image" />
            <div className="post-content">
              <h2 className="post-title">Give Back Grid Goes Live!</h2>
              <p className="post-metadata">Posted on May 29h, 2024 by A.B.</p>
              <p className="post-body">Users to claim a space, personalize it with an image or message...</p>
            </div>
          </article>
        </Link>
      </div>
    </div>
  );
}

export default Blog;
