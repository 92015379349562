import React, { useState, useEffect } from 'react';
import Grid from '../components/grid';
import HowToPopup from '../components/HowToPopup';
import { Helmet } from 'react-helmet'; // Import Helmet
// import Buy from '../components/Buy';
import './homepage.css';

function HomePage() {
  // State to control the visibility of the HowToPopup
  const [showHowToPopup, setShowHowToPopup] = useState(false);

  // Effect to check localStorage and determine the initial visibility of the popup
  useEffect(() => {
    const hasSeenPopup = localStorage.getItem('hasSeenHowToPopup');
    if (!hasSeenPopup) {
      setShowHowToPopup(true); // Show the popup if the user hasn't seen it before
    }
  }, []);

  // Function to toggle the popup visibility
  const toggleHowToPopup = () => {
    setShowHowToPopup(!showHowToPopup);
  };

  // Function to handle the closure of the popup and update localStorage
  const handleClosePopup = () => {
    localStorage.setItem('hasSeenHowToPopup', 'true'); // Save in localStorage
    setShowHowToPopup(false); // Hide the popup
  };

  return (
    <div className="home-page">
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={`${process.env.PUBLIC_URL}/images/gpg.png`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Join The Give Back Grid, where digital creativity meets charitable giving. Make a difference today." />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/images/gbg.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.PUBLIC_URL}/images/gbg.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.PUBLIC_URL}/images/gbg.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/images/site.webmanifest`} />
        <title>The Give Back Grid</title>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.givebackgrid.com/" />
        <meta property="og:title" content="The Give Back Grid" />
        <meta property="og:description" content="Join The Give Back Grid, where digital creativity meets charitable giving. Make a difference today." />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.givebackgrid.com/" />
        <meta name="twitter:title" content="The Give Back Grid" />
        <meta name="twitter:description" content="Join The Give Back Grid, where digital creativity meets charitable giving. Make a difference today." />
        <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/images/gbg.png`} />
      
        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-J27ZFN16BR"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-J27ZFN16BR');
          `}
        </script>
      </Helmet>
      {showHowToPopup && <HowToPopup onClose={handleClosePopup} />}
      <div className="grid-instruction-container">
        <div className="grid-instruction">
          <p>Click on an empty square to buy!</p>
          <p>Zoom in, click on a square that looks interesting, and be taken to the website of the contributor.</p>
          <button onClick={toggleHowToPopup} className="help-button">Need Help?</button>
        </div>
        <div className="main-content">
          <Grid />
          {/* <Buy /> */}
        </div>
      </div>
      <section className="why-donate">
        <h2>Why Donate with Us?</h2>
        <p>Joining The Give Back Grid means becoming part of a movement that combines creativity with philanthropy. Whether the grid is full or not, our platform ensures visibility for your contributions, attracting visitors who are eager to see the unique messages and images you share. By donating early, you stand out and make a lasting impression.</p>
        <p>Feel good advertising, knowing that every dollar you share, the more you get to see. Can't donate? Share the initiative <a href="https://www.givebackgrid.net" target="_blank" rel="noopener noreferrer">www.givebackgrid.net</a> on social media, every little bit helps. Join us in making a difference today!</p>
      </section>
    </div>
  );
}

export default HomePage;
